/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable dot-notation */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from "gatsby";
import 'jquery';


import { updateData } from '../data/contentful/actions';
import Layout from '../components/layout';

import Logo from '../components/logoSVG'

import FullPageLander from '../components/IndexPage/FullPageLander';
import SectionImageRight from '../components/IndexPage/SectionImageRight';
import SectionImageLeft from '../components/IndexPage/SectionImageLeft';
import SectionFitness from '../components/IndexPage/SectionFitness';
import SectionAboutClub from '../components/IndexPage/SectionAboutClub';
import SectionInstructors from '../components/IndexPage/SectionInstructors';
import SectionGallery from '../components/IndexPage/SectionGallery';
import SectionNews from '../components/IndexPage/SectionNews';
import ProductWidget from '../components/product/productWidget';

const layoutName = "1JiktKjiPZyxSZGAyDUWS8";

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.state,
    };
  }

  componentWillMount() {

  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.updateData();
  }

  render() {
    const { content } = this.props;
	const { assets, elements } = content.data;
	const { layouts } = content.data;
	const products = content.data.annexProduct.all;
    return (
      <Layout runAllMain>
		<section id="comingsoon1" className="ds section_padding_top_140 section_padding_bottom_150">
			<div className="container">
				<div className="row">
					<div className="col-sm-12 text-center">
						<img src={layouts[layoutName].logo.size({
							width: 350,
							format: 'png'
						})} alt="" />
						<h2 className="section_header highlight">Coming Soon!</h2>
					</div>
				</div>
			</div>
		</section>
      {/* <section className="intro_section page_mainslider ds all-scr-cover image-dependant half-mask">
				<div className="flexslider" data-dots="false" data-nav="true">
					<ul className="slides">
						<li>
						<div className="slide-image-wrap darken_gradient"> <img src="CMS/shutterstock_780911290.jpg" alt="" /> </div>
							<div className="slide-image-wrap svg to_animate" data-animation="fadeInRight">
								<Logo masked />
							</div>
							<div className="container">
								<div className="row">
									<div className="col-sm-12 text-right">
										<div className="slide_description_wrapper">
											<div className="slide_description">
												<div className="intro-layer" data-animation="fadeInLeft">
													<h2>Cleans<br/> Protects</h2>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
						<li>
							<div className="slide-image-wrap"> <img src="CMS/shutterstock_1289094682.jpg" alt="" /> </div>
							<div className="slide-image-wrap svg to_animate" data-animation="fadeInRight">
								<Logo masked />
							</div>
							<div className="container">
								<div className="row">
									<div className="col-sm-12 text-right">
										<div className="slide_description_wrapper">
											<div className="slide_description">
												<div className="intro-layer" data-animation="fadeInLeft">
													<h2>Dewaters<br/> Penetrates</h2>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
						<li>
							<div className="slide-image-wrap"> <img src="CMS/shutterstock_1289094682.jpg" alt="" /> </div>
							<div className="slide-image-wrap svg to_animate" data-animation="fadeInRight">
								<Logo masked />
							</div>
							<div className="container">
								<div className="row">
									<div className="col-sm-12 text-right">
										<div className="slide_description_wrapper">
											<div className="slide_description">
												<div className="intro-layer" data-animation="fadeInLeft">
													<h2>Lubicates<br/> Prevents Rust</h2>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</section>
			<section className="ds background_cover changeable section_padding_top_150 section_padding_bottom_150 columns_margin_bottom_40">
			</section>
			<section id="about" className="ds background_cover changeable section_padding_top_150 section_padding_bottom_150 columns_margin_bottom_40">
				<div className="container">
					<div className="row flex-wrap v-center">
						<span className="section_bg_header">Annex Biolubre</span>
						<h2 className="section_header big text-uppercase top-offset"><span className="highlight">Annex</span><br/> Approach</h2>
						<div className="col-xs-12 col-sm-12">
							<blockquote>
								<p>Annex Biolube is the result of research focussed on the development of lower toxicity, environmentally
									conscious lubricants formulated with renewable resources. Annex products combine enhanced
									anti-wear, anti-corrosion and anti-oxidation properties with high biodegradability and a reduced
									environmental footprint.</p>
							</blockquote>
							<p>
							Annex products are neither wet nor dry lubricants
– the Annex lubrication film will polymerise but will
not turn sticky over time. This specifically engineered
behaviour offers the benefits of both wet and dry lubricants
with the drawbacks of neither.

							</p>
							<h2 className="section_header text-uppercase">Dependable <span className="big highlight">Partner</span> <span className="small">Renewable</span> </h2>
							<p>
								As a system, Annex P3 and Annex V10 form an integral part of a maintenance program for highly specified,
								mission critical equipment:
							</p>
							<ul class="list2">
								<li>Annex P3 allows for the timely teardown and
									smooth reassembly of mobile and stationary plant.
								</li>
								<li>Annex V10 offers long term protection for moving
									parts with exposed metal surfaces when applied at
									suitably defined intervals.
								</li>
							</ul>
							<p>
								In the real world, tests demonstrate that Annex products
								deliver exceptional performance which gets the
								job done with reduced time and effort.
							</p>
							<p>
								In the laboratory, examination using equipment such
								as ASTM B117 Salt Fog apparatus and ASTM D4172
								Four Ball Wear apparatus confirms what mechanics
								and fitters tell us – that Annex products exceed the
								performance level of any other similar product tested
								against them.
							</p>
							<p>
								In the professional realm, Annex products find application
								in automotive, aviation, construction, earthmoving,
								engineering, electrical, farming, military,
								marine, mining, transport and many other sectors.
								In light and heavy industry, where increasing emphasis
								on Workplace Health and Safety and environmental
								accountability contribute to an ever-changing
								landscape, Annex Biolube meets the demands of today
								with the performance of tomorrow.
							</p>
						</div>
					</div>
				</div>
			</section>			
			<section id="products" className="cs section_padding_top_150 section_padding_bottom_150">
				<div className="container">
					<div className="row"> <span className="section_bg_header">For ever purpose</span>
						<h2 className="section_header big text-uppercase top-offset"><span className="highlight">Product</span><br/> Range</h2>
						<div className="col-xs-12">
						{products.map((p, i) => (
							<ProductWidget key={`relatedProduct-${i}`} product={content.data.annexProduct[p.id]} />
						))}
						</div>
					</div>
				</div>
			</section>			
			<section id="quote" className="ds background_cover changeable section_padding_top_150 section_padding_bottom_150 columns_margin_bottom_40">
				<div className="container">
					<div className="row flex-wrap v-center">
						<div className="col-xs-12 col-sm-6">
							<h2 className="section_header text-uppercase">Lubricant <span className="big highlight">bio-lubricant</span> <span className="small">Renewable</span> </h2>
							<blockquote>
								<p>General purpose, incidental bio-lubricant made from over 50% renewable materials.</p>
							</blockquote>
							<div className="toppadding_10 visible-lg"></div> <a href="#contact" className="theme_button color1 min_width_button">Enquire</a> </div>
						<div className="col-xs-12 col-sm-6 text-center text-sm-right">
							<div> <img src="/CMS/EcoFriendly.png" alt="" /> </div>
						</div>
					</div>
				</div>
			</section>
			<section id="services" className="cs section_padding_top_150 section_padding_bottom_150 columns_padding_30">
				<div className="container">
					<div className="row"> <span className="section_bg_header">What it does</span>
						<h2 className="section_header big text-uppercase top-offset"><span className="highlight">Maxmum Performance</span><br/>&nbsp;</h2>
						<div className="row">
							<div className="col-sm-12">
								<h3>Also known as general purpose lubricants, penetrating fluids are used in a wide range of automotive, industrial, commercial, domestic and other more highly specified applications.</h3>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-3">
								<div className="teaser text-center">
									<div className="teaser_icon size_normal highlight"> <i className="rt-icon2-chart-line-outline"></i> </div>
									<h3 className="text-uppercase highlight">Performance</h3>
									<p>Class-leading performance across the wide range of tasks a penetrant is often called upon to perform, including cleaning, dewatering, lu- bricating, protecting and inhibiting corrosion</p>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="teaser text-center">
									<div className="teaser_icon size_normal highlight"> <i className="rt-icon2-refresh-outline"></i> </div>
									<h3 className="text-uppercase highlight">Anti-wear</h3>
									<p>Lubrication film offers premium anti-wear, anti-oxidation &amp; anti-corrosion properties.</p>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="teaser text-center">
									<div className="teaser_icon size_normal highlight"> <i className="rt-icon2-rainy2"></i> </div>
									<h3 className="text-uppercase highlight">Protects </h3>
									<p>Inhibits corrosion of metal and alloys including steel, aluminium, copper and brass.</p>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="teaser text-center">
									<div className="teaser_icon size_normal highlight"> <i className="rt-icon2-directions"></i> </div>
									<h3 className="text-uppercase highlight">Universal</h3>
									<p>Neither a wet nor dry lubricant its lubrication film will polymerise but will not turn sticky over time. This specifically engineered behaviour gives the Annex V10 formulation the benefits of both wet and dry lubricants but the drawbacks of neither.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <section id="products" className="ds section_padding_top_150 section_padding_bottom_150">
				<div className="container">
					<div className="row"> <span className="section_bg_header">Superior Lubricating</span>
						<h2 className="section_header big text-uppercase top-offset">What to expect</h2>
						<div className="row">
							<div className="col-sm-6">
								<p>Extensive laboratory and real-world tests demon- strate that Annex V10 exceeds the performance lev- el of any other similar product tested against it. An example is the ASTM B117 Salt Fog apparatus, where testing showed Annex offers twice the performance of the next best product in saltwater environments.</p>
							</div>
							<div className="col-sm-6"></div>
						</div>
						<div className="row">
							<div className="col-sm-6"></div>
							<div className="col-sm-6">
								<p>Another example is the widely accepted ASTM D4162 Four Ball Wear test, which showed a greatly reduced metal-to-metal wear scar 25% less than the nearest competitor tested, indicating a superior film.</p>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-6"></div>
							<div className="col-sm-6">
								<p>These results show without doubt the superior lu- bricating qualities and anti-corrosion properties that are indicative of a durable lubrication film achieved by the Annex V10 formulation, and which offers pre- mium anti-wear, anti-corrosion and anti-oxidation performance</p>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-6"></div>
							<div className="col-sm-6">
								<p>In a range of applications from jet skis to precision military armaments, Annex V10 sets the standard.</p>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-6"></div>
							<div className="col-sm-6">
								<p>Annex V10 offers superior,  proven  performance in a myriad of applications for DIY and domestic use. In the professional realm it finds application in au- tomotive, aviation, construction, earthmoving, engi- neering, electrical, farming, military, marine, mining, transport and many other industries</p>
							</div>
						</div>
					</div>
				</div>
			</section>  
			<section id="features" className="cs section_padding_top_150 section_padding_bottom_150 columns_padding_30">
				<div className="container">
					<div className="row"> <span className="section_bg_header">Offical Applications</span>
						<h2 className="section_header big text-uppercase top-offset"><span className="highlight">Performance Designed</span><br/>&nbsp;</h2>
						<div className="row">
							<div className="col-sm-12">
								&nbsp;
							</div>
						</div>
						<div className="row">
						</div>
						<div className="row">
							<div className="col-sm-3">
								<div className="teaser text-center">
									<div className="teaser_icon size_normal"> <i className="rt-icon2-chart-line-outline"></i> </div>
									<h3 className="text-uppercase">Performance</h3>
									<p>Class-leading performance across the wide range of tasks a penetrant is often called upon to perform, including cleaning, dewatering, lu- bricating, protecting and inhibiting corrosion</p>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="teaser text-center">
									<div className="teaser_icon size_normal"> <i className="rt-icon2-refresh-outline"></i> </div>
									<h3 className="text-uppercase">Anti-wear</h3>
									<p>Lubrication film offers premium anti-wear, anti-oxidation &amp; anti-corrosion properties.</p>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="teaser text-center">
									<div className="teaser_icon size_normal"> <i className="rt-icon2-rainy2"></i> </div>
									<h3 className="text-uppercase">Protects </h3>
									<p>Inhibits corrosion of metal and alloys including steel, aluminium, copper and brass.</p>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="teaser text-center">
									<div className="teaser_icon size_normal"> <i className="rt-icon2-directions"></i> </div>
									<h3 className="text-uppercase white">Universal</h3>
									<p>Neither a wet nor dry lubricant its lubrication film will polymerise but will not turn sticky over time. This specifically engineered behaviour gives the Annex V10 formulation the benefits of both wet and dry lubricants but the drawbacks of neither.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>*/}
			{/* <section id="products" className="ds section_padding_top_150 section_padding_bottom_150">
				<div className="container">
					<div className="row"> <span className="section_bg_header">User Submitted</span>
						<h2 className="section_header big text-uppercase top-offset">People Tell Us</h2>
						<div className="row">
							<div className="owl-carousel" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-dots="true">
								<blockquote>
									<cite>
										<span className="small-text"></span>
									</cite>
									<p>I'm a mechanic in a busy workshop. In over 12 months testing development versions of Annex I’m yet to find a squealing fan belt this product won’t fix.</p>
								</blockquote>
								<blockquote>
									<cite>
										<span className="small-text"></span>
									</cite>
									<p>I changed the front struts in a small hatchback with 180,000 km on the clock in an hour and a half, saving an hour of labour. In thirty years as a mechanic I’ve never done a job like that so quickly and easily.</p>
								</blockquote>
								<blockquote>
									<cite>
										<span className="small-text"></span>
									</cite>
									<p>We use Annex to protect mild steel components in a high salt area. In testing, no corrosion was observed after months of continuous exposure.</p>
								</blockquote>
								<blockquote>
									<cite>
										<span className="small-text"></span>
									</cite>
									<p>I used Annex on the padlock and padbolt of my garden shed over 9 months ago. Still no corrosion in sight and the lock and padbolt both operate as new.</p>
								</blockquote>
								<blockquote>
									<cite>
										<span className="small-text"></span>
									</cite>
									<p>I tried silicone spray on the tracks of my garage door and after a month it needed to be reapplied. I tried Annex and 7 months on the door is still smooth and no reapplication necessary.</p>
								</blockquote>
								<blockquote>
									<cite>
										<span className="small-text"></span>
									</cite>
									<p>My Fanbelt tensioner was developing a squeal, so as a temporary fix I sprayed some Annex on it. That was 5 months ago. I haven't replaced the tensioner and still no noise.</p>
								</blockquote>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="testimonials" className="ds section_padding_top_150 columns_margin_0">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 text-center">
							<div className="owl-carousel" data-responsive-lg="1" data-responsive-md="1" data-responsive-sm="1" data-dots="true">
								<blockquote>
									<cite>
										<span className="small-text"></span>
									</cite>
									<p>I'm a mechanic in a busy workshop. In over 12 months testing development versions of Annex I’m yet to find a squealing fan belt this product won’t fix.</p>
								</blockquote>
								<blockquote>
									<cite>
										<span className="small-text"></span>
									</cite>
									<p>I changed the front struts in a small hatchback with 180,000 km on the clock in an hour and a half, saving an hour of labour. In thirty years as a mechanic I’ve never done a job like that so quickly and easily.</p>
								</blockquote>
								<blockquote>
									<cite>
										<span className="small-text"></span>
									</cite>
									<p>We use Annex to protect mild steel components in a high salt area. In testing, no corrosion was observed after months of continuous exposure.</p>
								</blockquote>
								<blockquote>
									<cite>
										<span className="small-text"></span>
									</cite>
									<p>I used Annex on the padlock and padbolt of my garden shed over 9 months ago. Still no corrosion in sight and the lock and padbolt both operate as new.</p>
								</blockquote>
								<blockquote>
									<cite>
										<span className="small-text"></span>
									</cite>
									<p>I tried silicone spray on the tracks of my garage door and after a month it needed to be reapplied. I tried Annex and 7 months on the door is still smooth and no reapplication necessary.</p>
								</blockquote>
								<blockquote>
									<cite>
										<span className="small-text"></span>
									</cite>
									<p>My Fanbelt tensioner was developing a squeal, so as a temporary fix I sprayed some Annex on it. That was 5 months ago. I haven't replaced the tensioner and still no noise.</p>
								</blockquote>
							</div>
						</div>
					</div>
				</div>
		</section>*/}
      </Layout>
    );
  }
}

const mapStateToProps = state => ({ state, content: state.contentful });

const mapDispatchToProps = dispatch => ({
  updateData: bindActionCreators(updateData, dispatch),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IndexPage);
